<template>
  <Forecast
    title="Watchlist Forecast"
    :current-spotify-followers="watchlistData.totalSpotifyListeners"
    :spotify-followers-a-year-ago="watchlistData.totalSpotifyListenersYearAgo"
  />
</template>

<script>
import Forecast from "../../common/components/Forecast";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  name: "WatchlistForecast",
  components: { Forecast },
  setup() {
    const store = useStore();
    const route = useRoute();

    const watchlistData = computed(() =>
      store.getters["UserModule/getWatchlistById"](Number(route.params.id))
    );
    return { watchlistData };
  },
};
</script>

<style scoped></style>
